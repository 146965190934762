





















 import { Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
import AddTeacher from "./Component/AddTeacher.vue";
import { Enums } from "@/Helper/Enum";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager } from "@syncfusion/ej2-data";
import {createElement} from "@syncfusion/ej2-base";
import { from } from "linq-to-typescript";
Vue.use(GridPlugin);
let dropLanguageInstance:any = null;
@Component({
     components: {
  },
  provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar]
  }
})
export default class Student extends Vue {
  dataManager = MyGridOption.getDataManager('TeacherAdminList');
  filterOptions = {    type: 'Menu'};
  wrapSettings= { wrapMode: 'Both ' };
  Languagefilter= {
        ui: {
            create: function (args:any) {
               
                let flValInput = createElement('input', { className: 'flm-input' });
                args.target.appendChild(flValInput);
                dropLanguageInstance = new DropDownList({
                dataSource: new DataManager(Enums.TeacherClassType),
                fields: { text: 'data', value: 'value' },
                placeholder: 'Select a value',
                popupHeight: '200px'
            });
            dropLanguageInstance.appendTo(flValInput);
            },
            write: function (args:any) {
                dropLanguageInstance.value = args.filteredValue;
            },
            read: function (args:any) {
                args.fltrObj.filterByColumn(args.column.field, args.operator, dropLanguageInstance.value);
            }
        }
    }
  editSettings= { allowAdding: true, allowDeleting: true, mode: 'Dialog',showDeleteConfirmDialog:true,  template: function() {
          return { template: AddTeacher };
        } };
    toolbar= [ 'Add', 'Delete'];
    actionFailure(args:any) {
      this.$store.commit("ChangeLoading", false);
      console.log(args);
      var t = args.error[0].error.response;
      console.log(t);
       this.$bus.$emit('testing_call',{data:t});
}
ClassType(field:any, data:any, column:any){
  var t = from(Enums.TeacherClassType).where(i=>i.value==data[field]).firstOrDefault()
  if(t!=null)
  return t.data;
  else
  return "";
 //return .data;
}
}
